import Highcharts from "highcharts";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  AreaSeries,
  Chart,
  HighchartsChart,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  withHighcharts,
} from "react-jsx-highcharts";
import { useSelector } from "react-redux";
import DownloadCsvIcon from "../../../../common/DownloadCsvIcon";
import { CotDataTransferChartApi } from "../../../../services/api";

const CotDataTransferChart = ({ id }) => {
  const [data, setData] = useState([]);
  const themes = useSelector((state) => state.cylenium.themes);
  useEffect(() => {
    Highcharts.setOptions({ global: { useUTC: false } });
    const fetchAndUpdateData = async () => {
      try {
        const response = await CotDataTransferChartApi(id);

        const newData = response.data.flatMap((group, offset) =>
          group.data.map((item, index) => {
            const typeLabel =
              group.type === "receive" ? "[Packets In(+)]" : "[Packets Out(-)]";
            return {
              id: offset * 100 + index,
              name: item.metric?.job,
              data: item.values[0].map((time, idx) => [
                time * 1000,
                (parseFloat(item.values[1][idx]) / 1024) * (offset ? -1 : 1),
              ]),
              customType: typeLabel,
            };
          })
        );

        setData(newData);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchAndUpdateData();
    const intervalId = setInterval(fetchAndUpdateData, 10000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <DownloadCsvIcon data={data} name={"CotDataTransferChartReport"} />

      <HighchartsChart>
        <Chart
          backgroundColor="none"
          height="280px"
          style={{ fontSize: "14px", fontWeight: 600, color: "#7b7c87" }}
        />
        <Legend
          enabled={true}
          layout="horizontal"
          align="center"
          verticalAlign="bottom"
          alignColumns={"center"}
          maxHeight={100}
          navigation={{
            activeColor: "#3E576F",
            inactiveColor: "#CCC",
            arrowSize: 12,
            style: {
              fontWeight: "bold",
              color: "#333",
              fontSize: "12px",
            },
          }}
          style={{ height: 30 }}
          labelFormatter={function () {
            return `${this.name}: ${Math.abs(
              this.yData.reduce((a, b) => a + b, 0) / this.yData.length
            ).toFixed(2)} Avg. (kB/s) ${this.options.customType}`;
          }}
        />
        <Tooltip
          backgroundColor="#2a2e31"
          style={{ color: "#cccccc", fontSize: "10px" }}
          useHTML
          shared
          formatter={function () {
            return (
              `<b class="font-size12">${moment(this.x).format(
                "MM-DD-YYYY HH:mm:ss"
              )}</b><br/>` +
              this.points
                .map(
                  (point) =>
                    `<div class="text1">
                        <span style="color:${point.color}">●</span> 
                        <span class="series-name">${point.series.name}</span>
                        <span class="chart-value"><b>${Math.abs(
                          point.y
                        ).toFixed(2)} kB/s ${
                      point.series.options.customType
                    }</b></span>
                    </div>`
                )
                .join("")
            );
          }}
        />
        <XAxis type="datetime" crosshair />
        <YAxis
          crosshair
          labels={{
            formatter: function () {
              return `${this.value} kB/s`;
            },
          }}
          plotLines={[
            {
              value: 0,
              color: themes !== 3 ? "white" : "black", // Darker 0 kB/s line
              width: 1, // Adjust thickness to match -1 kB/s line
              zIndex: 2, // Ensures it appears above other lines
            },
          ]}
        >
          <YAxis.Title style={{ color: themes !== 3 ? "white" : "black" }}>
            Packets out (-) / in(+)
          </YAxis.Title>
          {data.map((series) => {
            return (
              <AreaSeries
                key={series.id}
                name={series.name}
                data={series.data}
                customType={series.customType} // Pass custom type here
                fillOpacity={0.1}
                lineWidth={1}
                marker={{ enabled: false }}
              />
            );
          })}
        </YAxis>
      </HighchartsChart>
    </div>
  );
};

export default withHighcharts(CotDataTransferChart, Highcharts);
