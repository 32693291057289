import { IconButton } from "@mui/material";
import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CiEdit } from "react-icons/ci";
import * as Yup from "yup";
import ChangePasswordModal from "./ChangePasswordModal";
import DeviceListModal from "./DeviceListModal";

import { yupResolver } from "@hookform/resolvers/yup";
import Search from "antd/es/input/Search";
import QueryString from "qs";
import { BsListTask } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../../common/CustomInput";
import CustomDropdownWithoutLabel from "../../../common/UI/CustomDropdownWithoutLabel";
import CustomRadioGroup from "../../../common/UI/CustomRadioGroup";
import CustomSwitch from "../../../common/UI/CustomSwitch";
import {
  getAllDeviceListAction,
  getDeviceSettingByIdAction,
  updateDeviceSettingAction,
} from "../../../redux/action/action";

const wifi_profile_options = [
  { value: "2.4 GH", label: "2.4 GHz" },
  { value: "5 GH", label: "5 GHz" },
];

const options = [
  { value: "high", label: "High" },
  { value: "medium", label: "Medium" },
  { value: "low", label: "Low" },
  { value: "none", label: "None" },
];
const defaultFormValues = {
  mobile_data: false,
  wifi_service: false,
  wifi_profile: "",
  wifi_service_name: "Armia 1",
  notification: false,
  remote_management: false,
  software_update: false,
  protect_mode: false,
  monitoring_mode: false,
  ghost_mode: false,
  quarantine_status: "none",
};

const validationSchema = Yup.object({
  wifi_name_ssid: Yup.string().required("Name is required"),
});
const Index = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [checkbox, setCheckbox] = useState(false);

  const [open, setOpen] = useState(false);

  const [param, setParam] = useState({
    search: "",
    page: 1,
    page_size: 10,
    filter: [],
    status: undefined,
    selectedDevice: "",
  });
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setValue(
      "wifi_name_ssid",
      deviceSetting?.device_name ? deviceSetting?.device_name : ""
    );
    setIsModalOpen(false);
  };
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const onSubmit = (data) => {
    // checking update field with previews payload it is changed or not
    let payload = {};
    if (data?.mobile_data != deviceSetting?.mobile_data) {
      payload = { ...payload, mobile_data: data?.mobile_data ? 1 : 0 };
    }
    if (data?.wifi_name_ssid != deviceSetting?.wifi_name_ssid) {
      payload = { ...payload, wifi_name_ssid: data?.wifi_name_ssid ? 1 : 0 };
    }
    if (data?.wifi_profile != deviceSetting?.wifi_profile) {
      payload = { ...payload, wifi_profile: data?.wifi_profile };
    }
    if (data?.notification != deviceSetting?.notification) {
      payload = { ...payload, notification: data?.notification ? 1 : 0 };
    }
    if (data?.remote_mgt != deviceSetting?.remote_mgt) {
      payload = { ...payload, remote_mgt: data?.remote_mgt ? 1 : 0 };
    }
    if (data?.software_update != deviceSetting?.software_update) {
      payload = { ...payload, software_update: data?.software_update ? 1 : 0 };
    }
    if (data?.ghost_mode != deviceSetting?.ghost_mode) {
      payload = { ...payload, ghost_mode: data?.ghost_mode ? 1 : 0 };
    }
    if (data?.protect_mode != deviceSetting?.protect_mode) {
      payload = { ...payload, protect_mode: data?.protect_mode ? 1 : 0 };
    }
    if (data?.monitoring_mode != deviceSetting?.monitoring_mode) {
      payload = { ...payload, monitoring_mode: data?.monitoring_mode ? 1 : 0 };
    }
    if (data?.ethernet_host != deviceSetting?.ethernet_host) {
      payload = { ...payload, ethernet_host: data?.ethernet_host ? 1 : 0 };
    }
    if (data?.wifi_mode != deviceSetting?.wifi_mode) {
      payload = { ...payload, wifi_mode: data?.wifi_mode ? 1 : 0 };
    }

    if (data?.wifi_service != deviceSetting?.wifi_service) {
      payload = { ...payload, wifi_service: data?.wifi_service ? 1 : 0 };
    }
    if (data?.wifi_name_ssid != deviceSetting?.wifi_name_ssid) {
      payload = { ...payload, wifi_name_ssid: data?.wifi_name_ssid };
    }
    if (data?.openvas_autoscan_flag != deviceSetting?.openvas_autoscan_flag) {
      payload = {
        ...payload,
        openvas_autoscan_flag: data?.openvas_autoscan_flag,
      };
    }
    if (data?.auto_block != deviceSetting?.auto_block) {
      payload = {
        ...payload,
        auto_block: data?.auto_block,
      };
    }
    if (data?.openvas_autoscan_value != deviceSetting?.openvas_autoscan_value) {
      payload = {
        ...payload,
        openvas_autoscan_value: data?.openvas_autoscan_value,
      };
    }
    if (data?.device_name != deviceSetting?.device_name) {
      payload = { ...payload, device_name: data?.device_name };
    }
    if (data?.new_password != deviceSetting?.wifi_password) {
      payload = { ...payload, wifi_password: data?.new_password };
    }

    let tempDevicelist = [];
    //if we  selected multiple devices
    if (checkbox) {
      selectedDevices?.forEach((record) => {
        if (record?.checked) tempDevicelist?.push(record.device_id);
      });
    } else {
      tempDevicelist = [selectedDevice];
    }

    dispatch(
      updateDeviceSettingAction(
        { settings: payload, devices: tempDevicelist },
        QueryString.stringify({ selectedDevice })
      )
    );
  };
  const records = useSelector((state) => state.cylenium.allDeviceList);
  const deviceSetting = useSelector((state) => state.cylenium.deviceSetting);

  const dispatch = useDispatch();
  const getDeviceList = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    dispatch(getAllDeviceListAction(QueryString.stringify(payload)));
  };
  const getDeviceInfoById = (device_id) => {
    setSelectedDevice(device_id);
    dispatch(getDeviceSettingByIdAction(QueryString.stringify({ device_id })));
  };
  useEffect(() => {
    getDeviceList();
  }, [param]);
  useEffect(() => {
    reset(deviceSetting);
  }, [deviceSetting]);

  useEffect(() => {
    if (records?.length) {
      const selectedRecords = records.filter((data) => data.selected == 1);
      getDeviceInfoById(selectedRecords[0]?.device_id);
    }
  }, [records]);

  return (
    <div>
      <div className="body-r-container">
        <div className="page-wrap">
          <div className="device-wrap">
            <div className="page-head d-flex align-items-center justify-content-between mr-b20">
              <div className="page-title">
                <h3 className="font-size18 text-white Inter-Bold">
                  Device Settings{" "}
                </h3>
              </div>
            </div>
            <BsListTask
              onClick={() => setOpen(!open)}
              className="list-device"
            />
            <div
              className={`ds-board ${open ? "show-device" : "hide-device"}  `}
            >
              <div className="ds-search-col">
                <div className="ds-list">
                  <div className="ds-serach-block">
                    <Search
                      onChange={(event) =>
                        setParam({ ...param, search: event.target.value })
                      }
                      placeholder="Search"
                    />
                  </div>
                  <div className="list-block">
                    <ul>
                      {records?.length &&
                        records.map((record) => (
                          <li
                            key={record.device_id}
                            className={
                              record?.device_id == selectedDevice
                                ? "selected_device item-active"
                                : "item-active"
                            }
                            onClick={() => getDeviceInfoById(record.device_id)}
                          >
                            {record.device_name}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ds-resuld-col">
                <div className="ds-r-container">
                  <div className="ds-r-block">
                    <div className="over-wrap">
                      <div className="b-card-row cus-scroll">
                        <form onSubmit={handleSubmit(onSubmit)} noValidate>
                          <h4 className="font-size16 text-white Inter-Bold">
                            {deviceSetting?.device_name}
                          </h4>
                          <div className="info-black-card mr-b20 mt-2">
                            <div className="tab-content" id="myTabContent">
                              <h4 className="font-size12 text-white Inter-Regular mr-b20">
                                Network{" "}
                              </h4>
                              <div className="row">
                                <div className="col-sm-3">
                                  <label className="switch-label">
                                    Mobile Data
                                  </label>
                                  <CustomSwitch
                                    size="small"
                                    name="mobile_data"
                                    control={control}
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch-label">
                                    Wi-Fi Service{" "}
                                  </label>
                                  <CustomSwitch
                                    size="small"
                                    name="wifi_service"
                                    control={control}
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch-label">
                                    Network Tethering{" "}
                                  </label>
                                  <CustomSwitch
                                    size="small"
                                    name="ethernet_host"
                                    control={control}
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch-label">
                                    Wi-Fi Profile{" "}
                                  </label>
                                  <CustomRadioGroup
                                    name="wifi_profile"
                                    control={control}
                                    options={wifi_profile_options}
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch-label">
                                    Wi-Fi Name (SSID)
                                  </label>{" "}
                                  <div style={{ marginTop: -10 }}>
                                    <span className="span-title">
                                      {watch("wifi_name_ssid")}
                                    </span>
                                    <IconButton onClick={showModal}>
                                      <CiEdit />{" "}
                                    </IconButton>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="info-black-card mr-b20 mt-2">
                            <div className="tab-content" id="myTabContent">
                              <h4 className="font-size12 text-white Inter-Regular mr-b20">
                                Device{" "}
                              </h4>
                              <div className="row">
                                <div className="col-sm-3">
                                  <label className="switch-label">
                                    Notifications{" "}
                                  </label>
                                  <CustomSwitch
                                    size="small"
                                    name="notification"
                                    control={control}
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch-label">
                                    Remote Management{" "}
                                  </label>
                                  <CustomSwitch
                                    size="small"
                                    name="remote_mgt"
                                    control={control}
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch-label">
                                    Software Update{" "}
                                  </label>
                                  <CustomSwitch
                                    size="small"
                                    name="software_update"
                                    control={control}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="info-black-card mr-b20 mt-2">
                            <div className="tab-content" id="myTabContent">
                              <h4 className="font-size12 text-white Inter-Regular mr-b20">
                                Security{" "}
                              </h4>
                              <div className="row">
                                <div className="col-sm-3">
                                  <label className="switch-label">
                                    Protect Mode{" "}
                                  </label>
                                  <CustomSwitch
                                    size="small"
                                    name="protect_mode"
                                    control={control}
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch-label">
                                    Monitoring Mode{" "}
                                  </label>
                                  <CustomSwitch
                                    size="small"
                                    name="monitoring_mode"
                                    control={control}
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch-label">
                                    Ghost Mode{" "}
                                  </label>
                                  <CustomSwitch
                                    size="small"
                                    name="ghost_mode"
                                    control={control}
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch-label">
                                    Auto Block{" "}
                                  </label>
                                  <CustomSwitch
                                    size="small"
                                    name="auto_block"
                                    control={control}
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch-label">
                                    Auto Scan{" "}
                                  </label>
                                  <CustomSwitch
                                    size="small"
                                    name="openvas_autoscan_flag"
                                    control={control}
                                  />
                                </div>

                                <div className="col-sm-3">
                                  <label className="switch-label">
                                    Quarantine Status{" "}
                                  </label>
                                  <CustomDropdownWithoutLabel
                                    varient="outline"
                                    control={control}
                                    defaultValue="none"
                                    name="openvas_autoscan_value"
                                    error={errors?.openvas_autoscan_value}
                                    options={options}
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <ChangePasswordModal
                            control={control}
                            errors={errors}
                          />
                          <div className="text-danger">
                            <b>Note* :</b> Your changes will not be applied
                            until you save them. Click the 'Save' button to
                            ensure your updates are reflected.
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <DeviceListModal
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    selectedDevices={selectedDevices}
                    setSelectedDevices={setSelectedDevices}
                    checkbox={checkbox}
                    setCheckbox={setCheckbox}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Wi-Fi Service"
        open={isModalOpen}
        onOk={handleOk}
        okText="Apply"
        cancelText="Cancel"
        onCancel={handleCancel}
        footer={[
          <Button
            key="cancel-button"
            className="custom-cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button
            className="submit-Button"
            // onClick={handleSubmit(onSubmit)}
            onClick={handleOk}
          >
            Save
          </Button>,
        ]}
      >
        <form
        // onSubmit={handleSubmit(onSubmit)}
        >
          <div className="row">
            <div className="col-8 mt-3">
              <CustomInput
                label={"Name"}
                name="wifi_name_ssid"
                varient="outline"
                control={control}
                error={errors?.device_name}
                fullWidth
              />{" "}
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Index;
