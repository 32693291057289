import Highcharts from "highcharts";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  AreaSeries,
  Chart,
  HighchartsChart,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  withHighcharts,
} from "react-jsx-highcharts";
import { useSelector } from "react-redux";
import DownloadCsvIcon from "../../../../common/DownloadCsvIcon";
import { getSecurityEventsApi } from "../../../../services/api";
import { chartColor } from "../../../../utils/colors";
import { useParams } from "react-router-dom";
import { Table } from "antd";
const SecurityEventsChart = () => {
  const [data, setData] = useState([]);
  const themes = useSelector((state) => state.cylenium.themes);
  const arr = [{ name: "kl", valeu: 1 }];
  const params = useParams(); //group id
  const chartRef = useRef(null);

  const [infoWindowData, setInfoWindowData] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [locked, setLocked] = useState(false);
  const infoWindowRef = useRef(null);
  const hoverTimerRef = useRef(null);

  const handlePointMouseOver = (e, point) => {
    // Clear any existing hover timer
    if (hoverTimerRef.current) {
      clearTimeout(hoverTimerRef.current);
    }

    // Set a small delay to prevent flickering when moving between points
    hoverTimerRef.current = setTimeout(() => {
      const chartOffset = chartRef.current?.container?.getBoundingClientRect();
      if (!chartOffset) return;

      setInfoWindowData({
        ...point,
        dataForRow: point?.series?.options?.data[point?.index][2],
        formattedTime: moment(point.x).format("MM-DD-YYYY HH:mm:ss"),
      });
      const plotX = point.plotX;
      const plotY = point.plotY;

      const chart = point.series.chart;
      const chartX = chart.plotLeft + plotX;
      const chartY = chart.plotTop + plotY;
      // Set position once and don't update it on mouse movement
      setPosition({
        x: chartX,
        y: chartY,
      });

      // Automatically lock the info window when hovering over a point
      setLocked(true);
    }, 50);
  };

  // Handle mouse leaving the chart - not needed for automatic locking
  const handleMouseOut = () => {
    // We don't hide the info window anymore when the mouse leaves
    // since we want it locked for interaction
    if (hoverTimerRef.current) {
      clearTimeout(hoverTimerRef.current);
    }
  };

  // Close the info window when clicking outside of it
  const handleOutsideClick = (e) => {
    if (
      locked &&
      infoWindowRef.current &&
      !infoWindowRef.current.contains(e.target)
    ) {
      // Close the info window on any click outside
      setLocked(false);
      setInfoWindowData(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
      // Clear any pending timers on component unmount
      if (hoverTimerRef.current) {
        clearTimeout(hoverTimerRef.current);
      }
    };
  }, [locked]);

  useEffect(() => {
    Highcharts.setOptions({ global: { useUTC: false } });
    const fetchAndUpdateData = async () => {
      try {
        const response = await getSecurityEventsApi(params.id);
        const newData = response?.data?.map((item, index) => ({
          id: 1 * 100 + index,
          name: item.metrics?.job,

          data: item.values[0].map((time, idx) => [
            time * 1000,
            parseFloat(item.values[1][idx]),
            item.values[2][idx],
          ]),
        }));
        setData(newData);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchAndUpdateData();
    const intervalId = setInterval(fetchAndUpdateData, 10000);
    return () => clearInterval(intervalId);
  }, [params]);

  const columns = [
    {
      title: "Device Name",
      dataIndex: "host_name",
      key: "host_name",
      render: (data) => (
        <div className="overflow-text1" title={data}>
          {data}
        </div>
      ),
    },
    {
      title: "IP Address",
      dataIndex: "ip_address",
      key: "ip_address",
      render: (text) => (
        <div className="ellipsis-cell" title={text}>
          {text}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (data) => (
        <div className="overflow-text1" title={data}>
          {data}
        </div>
      ),
    },
    {
      title: "Message",
      dataIndex: "Message",
      key: "Message",
      render: (text) => (
        <div className="ellipsis-cell" title={text}>
          {text}
        </div>
      ),
    },
    {
      title: "Count",
      dataIndex: "value",
      key: "value",
      render: (text) => (
        <div className="ellipsis-cell" title={text}>
          {text}
        </div>
      ),
    },
  ];

  // Check if data is available for table display
  const hasTableData =
    infoWindowData?.dataForRow && infoWindowData?.dataForRow?.length > 0;
  const getInfoWindowPosition = () => {
    if (!infoWindowData) return {};

    const windowWidth = window.innerWidth;
    const pointX = position.x;
    const infoWindowWidth = 300; // Approximate width of info window

    // Position info window to the left of the point, with adjustment to make sure it's visible
    // Check if there's enough space to the left of the point
    const leftPosition = pointX - infoWindowWidth - 20; // 20px padding

    if (leftPosition < 0) {
      // Not enough space on the left, position to the right with some offset
      return {
        top: position.y - 10, // Small offset from the point vertically
        left: pointX + 20, // Position to the right with some padding
        transform: "translateY(-50%)", // Center vertically
      };
    } else {
      // Position to the left of the point
      return {
        top: position.y - 10, // Small offset from the point vertically
        left: leftPosition,
        transform: "translateY(-50%)", // Center vertically
      };
    }
  };
  return (
    <div style={{ position: "relative" }}>
      <DownloadCsvIcon data={data} name={"SecurityEventsChartReport"} />
      <HighchartsChart callback={(chart) => (chartRef.current = chart)}>
        <Chart
          backgroundColor="none"
          height="280px"
          style={{ fontSize: "14px", fontWeight: 600, color: "#7b7c87" }}
        />
        <Legend
          enabled={true}
          layout="horizontal"
          align="center"
          verticalAlign="bottom"
          alignColumns={"center"}
          maxHeight={100}
          navigation={{
            activeColor: "#3E576F",
            inactiveColor: "#CCC",
            arrowSize: 12,
            style: {
              fontWeight: "bold",
              color: "#333",
              fontSize: "12px",
            },
          }}
          style={{ height: 30 }}
        />

        <XAxis type="datetime" crosshair />
        <YAxis
          crosshair
          labels={{
            formatter: function () {
              return `${this.value}`;
            },
          }}
        >
          <YAxis.Title style={{ color: themes !== 3 ? "white" : "black" }}>
            Events
          </YAxis.Title>
          {data.map((series) => (
            <AreaSeries
              key={series.id}
              name={series.name}
              arr={arr}
              data={series.data}
              events={{
                mouseOut: handleMouseOut,
              }}
              point={{
                events: {
                  mouseOver: function (e) {
                    handlePointMouseOver(e, this);
                  },
                },
              }}
              fillOpacity={0.1}
              lineWidth={2}
              marker={{ enabled: false }}
              animation={true}
            />
          ))}
        </YAxis>
      </HighchartsChart>
      {infoWindowData && (
        <div
          id="custom-info-window"
          ref={infoWindowRef}
          onClick={(e) => e.stopPropagation()}
          style={{
            position: "absolute",
            ...getInfoWindowPosition(),
            backgroundColor: themes !== 3 ? "#2a2e31" : "white",
            color: themes !== 3 ? "#cccccc" : "#333333",
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
            zIndex: 999,
            minWidth: "250px",
            maxWidth: "400px",
            pointerEvents: "auto",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <b>{infoWindowData.formattedTime}</b>
            <div>
              <button
                onClick={() => {
                  setLocked(false);
                  setInfoWindowData(null);
                }}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  color: themes !== 3 ? "#cccccc" : "#333333",
                  fontSize: "14px",
                }}
              >
                ✕
              </button>
            </div>
          </div>
          <div className="text1">
            <div>
              <span style={{ color: infoWindowData.color }}>●</span>
              {infoWindowData.series.name}{" "}
            </div>
            <div>{infoWindowData.y}</div>
          </div>
          <br /> <b className="chart-details">Details</b>
          <div
            style={{
              maxHeight: hasTableData ? "150px" : "auto",
              overflowY: hasTableData ? "auto" : "visible",
            }}
          >
            {hasTableData ? (
              <Table
                columns={columns}
                dataSource={infoWindowData?.dataForRow}
                size="middle"
                pagination={false}
                bordered
                className="custom-table-1"
                style={{ fontSize: "10px" }}
                rowKey={(record, idx) => idx}
              />
            ) : (
              <div style={{ textAlign: "center", padding: "15px 0" }}>
                No data
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default withHighcharts(SecurityEventsChart, Highcharts);
